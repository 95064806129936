.banner__carousel{
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	.carousel-inner{
		max-width: 1920px;
		width: 160%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.carousel-indicators{
		justify-content: center;
		bottom: 30px;

		li{
			position: relative;
			width: 20px;
			height: 20px;
			border: 2px solid transparent;
			border-radius: 50%;
			background-color: transparent;
			opacity: 1;

			&.active{
				border: 2px solid #fff;
			}

			&:after{
				position: absolute;
				content: '';
				width: 14px;
				height: 14px;
				background-color: #fff;
				border-radius: 50%;
				top: calc(50% - 7px);
				left: calc(50% - 7px);
				opacity: 1;
				transition: all 0.3s linear;
			}
		}
	}
}
.produto__card{
	max-width: 258px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
	margin-bottom: 20px;

	a{
		&:hover{
			text-decoration: none;
		}
	}

	&:hover{
		.produto__card__link__grande{
			.produto__card__botoes{
				opacity: 1;
			}
			.produto__card__imagem{
				&:after{
					opacity: 1;
				}
			}
		}
	}

	.produto__card__link__grande{
		position: relative;

		.produto__card__botoes{
			position: absolute;
			left: 0px;
			width: 100%;
			bottom: 0px;
			display: flex;
			justify-content:  center;
			padding: 5px;
			border-radius: 20px;
			z-index: 30;

			.carrinho__card__botao{
				width: 130px;
				height: 40px;
				background: rgba(119,119,119,1);
				background: linear-gradient(180deg, rgba(222,222,222,1) 0%, rgba(119,119,119,1) 100%);
				border-radius: 40px;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border: none;
				transition: all 0.3s linear;
				font-family: 'montserratsemibold';
				padding: 0px 15px 0px 10px;

				.carrinho__card__icone{
					background-color: #fff;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 28px;
					color: rgba(119,119,119,1);
					height: 28px;
				}

				&:hover{
					background-color: #2b2c30;
					color: #fff;
				}

			}
			.carrinho__card__botao{
				margin-bottom: 5px;
			}

			@include media-breakpoint-up (lg) {
				opacity: 0;
			}
		}
	}
	.produto__card__link__grande{
		position: relative;
		margin-bottom: 10px;

		.produto__card__imagem{
			position: relative;
			z-index: 20;
			border: 1px solid #e4e4e4;

			&:after{
				position: absolute;
				z-index: 10;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				content: '';
				background-color: rgba(#000, 0.3);
				opacity: 0;
				transition: all 0.3s linear;
			}
		}

		a{
			display: block;
			position: relative;
		}

		img{
			position: relative;
			z-index: 5;
		}


		.produto__card__tags{
			position: absolute;
			top: 10px;
			left: 10px;
			z-index: 15;

			.produto__card__tag{
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #2b2c30;
				width: 54px;
				height: 54px;
				border-radius: 50%;
				margin-bottom: 10px;
				font-family: 'montserratbold';
				font-size: 13px;
				line-height: 13px;
				color: #fff;
			}
		}
	}
	.produto__card__imagem{
		position: relative;
	}
}
.banner__links{
	padding: 60px 0px;
}
.banner__link{
	display: block;
	max-width: 350px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}
.produto__destaque{
	padding-bottom: 30px;
}
.produto__card__titulo{
	font-family: 'montserratsemibold';
	font-size: 15px;
	line-height: 19px;
	color: #666666;
	text-align: center;
	height: 38px;
	--linhas: 2;
}
.produto__card__valor{
	display: flex;
	justify-content: center;
	font-size: 15px;
	line-height: 18px;
	align-items: center;
	color: #666666;

	.produto__valor__total{
		font-size: 18px;
		font-family: 'montserratbold';
		margin-left: 3px;
	}
	.produto__valor__promocao{
		text-decoration: line-through;
	}
}
.produto__card__parcelamento{
	text-align: center;
	font-size: 13px;
	line-height: 15px;
	color: #666666;
}
.titulo__secao{
	font-family: 'montserratextrabold';
	font-size: 30px;
	line-height: 34px;
	color: #666666;
	margin-bottom: 40px;
	text-align: center;
}
.newsletter{
	position: relative;
	padding-top: 47px;

	.container{
		padding-bottom: 47px;
		border-bottom: 1px solid rgba(#fff, 0.1);
		margin-bottom: 50px;
	}

	&:before{
		content: '';
		position: absolute;
		top: 0px;
		width: 100%;
		height: 100%;
		background-image: url('../images/bg__news.jpg');
		background-color: #2b2c30;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		left: 0px;
		z-index: -1;

		@include media-breakpoint-down (sm) {
			height: 600px;
		}
	}
}
.titulo__news{
	font-family: 'montserratextrabold';
	font-size: 30px;
	line-height: 34px;
	color: #fff;
	text-align: center;
	margin-bottom: 12px;
}
.news__desc{
	text-align: center;
	color: #fff;
	margin-bottom: 22px;
}
.news__form{
	background-color: #fff;
	border-radius: 60px;
	overflow: hidden;

	@include media-breakpoint-down (md) {
		background-color: transparent;
		border-radius: 0px;
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;

		.form-group{
			width: 100%;
		}

		.news__input{
			border-radius: 60px;
		}
	}

	@include media-breakpoint-up (lg) {
		display: grid;
		grid-template-columns: calc(50% - 80px) calc(50% - 80px) 160px;

		.form-group{
			margin-bottom: 0px;
			position: relative;

			&:first-child{
				&:after{
					background-color: #d1d1d1;
					width: 1px;
					height: 35px;
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0px;
				}
			}
		}
	}

	.news__input{
		display: block;
		width: 100%;
		height: 60px;
		background-color: #fff;
		border: none;
		padding: 22px 30px;
		font-size: 14px;
		line-height: 16px;
		font-family: 'montserratitalic';
	}
	.news__btn{
		background: rgba(119,119,119,1);
		background: linear-gradient(180deg, rgba(222,222,222,1) 0%, rgba(119,119,119,1) 100%);
		color: #fff;
		border-radius: 60px;
		border: none;
		font-family: 'montserratsemibold';
		font-size: 13px;
		line-height: 13px;
		padding-top: 5px;
		width: 160px;
		height: 60px;

		&:focus{
			outline: none;
		}
	}
}
.mais__vendidos{
	.titulo__news{
		margin-bottom: 30px;
	}
}
.produtos__destaque{
	padding-bottom: 30px;
}
.produto__detalhe__box{
	.qtde-toggler{
		.input__qtd{
			background-color: #fff;
		}
	}
}
