.titulo__compra{
	font-family: 'montserratregular';
	font-size: 30px;
	line-height: 34px;
	color: #000;
	margin-bottom: 30px;
}
.titulo__pagamento__interna{
	font-family: 'montserratbold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 15px;
	color: #000;
}
.pagamento__desc{
	font-size: 14px;
	line-height: 18px;
	color: #000;
    margin-bottom: 25px;
    font-family: 'montserratsemibold';
    font-weight: 600;
    letter-spacing: 0.6px;
}
.pagamento__valor{
	font-size: 14px;
	line-height: 18px;
    color: #000;
    font-family: 'montserratsemibold';

	span{
		color: #000;
	}
}
.pagamento__card{
	padding-bottom: 44px;
	border-bottom: 8px solid #DDDDDD;
	margin-bottom: 30px;

	&:last-child{
		border-bottom: none;
	}
}
.segura__btns__pagamento{
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	height: 100%;

	img{
		margin-bottom: 10px;
	}
}
.btn__finaliza__compra{
	box-shadow: 0px 2px #aeb3aa;
	color: #fff;
	background-color: #00C100;
	border-color: #00C100;
	line-height: 14px;
	padding-left: 50px;
	min-width: 200px;
	text-align: left;
	position: relative;
	font-size: 10px;
    line-height: 12px;
	letter-spacing: 0px;
	font-family: 'montserratbold';
	border-radius: 5px;
	position: relative;
	transition: all 0.3s linear;

	&:hover{
		background-color: darken(#00C100, 10%);
		border-color: darken(#00C100, 10%);
		color: #fff;
		text-decoration: none;
	}


    &.alt__pagamento{
        background-color: #00C100;
        border-color: #00C100;

        &:hover{
            background-color: darken(#00C100, 10%);
            border-color: darken(#00C100, 10%);
        }
    }
	span{
		display: block;
		padding: 0.15rem 0px 0.15rem 0.25rem;

		&:before{
			content: '';
			height: 100%;
			border-left: 3px solid rgba(93, 169, 20, 0.2);
			position: absolute;
			top: 0px;
			left: 50px;
		}
	}

	&:before{
		width: 20px;
		height: 10px;
		background-color: currentColor;
		left: 0.75rem;
		top: 50%;
		transform: translateY(-50%);
		content: '';
		position: absolute;
	}
	&:after{
		content: '';
		left: calc(0.75rem + 20px);
		top: calc(50% - 10px);
		border: 10px solid transparent;
		border-left-color: #fff;
		position: absolute;
		display: block;
	}
}
.col__detalhes{
	width: 100%;
	max-width: 300px;
	padding: 0px 0px;
}
.pedido__conteudo{
	border-left: 2px solid #ededed;
	border-right: 2px solid #ededed;
	border-bottom: 2px solid #ededed;
}
.row__finalizar{
	display: flex;
}
.detalhe__header__pedido{
	font-family: 'montserratbold';
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	background-color: #2b2c30;
	display: flex;
	justify-content: center;
	align-items: center;
    padding: 17px 18px 15px 18px;
	text-align: center;
}
.detalhe__header__menor{
	padding: 6px 17px 6px 17px;
	font-family: 'montserratbold';
	font-size: 20px;
	line-height: 24px;
	color: #000;
	background-color: #e7e7e9;
	display: flex;
	justify-content: center;
    text-align: center;
	align-items: center;
}
.detalhe__endereco__conteudo{
	padding: 10px 15px 15px 15px;
	margin-bottom: 10px;
	border-top: none;
}
.detalhe__endereco__nome{
	font-family: 'montserratregular';
	font-size: 13px;
	line-height: 17px;
	color: #000;
	margin-bottom: 10px;
}
.detalhe__endereco__desc{
	font-family: 'montserratregular';
	font-size: 13px;
	line-height: 17px;
	color: #000;
}
.tabela__pedido{
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 17px;
	color: #000;
	margin-bottom: 0px;
	margin-right: 0px;
	width: 100%;
	font-weight: 500;
	margin-top: 3px;
	margin-bottom: 30px;

	thead{
		th{
			background-color: #e7e7e9;
			font-family: 'montserratbold';
			color: #000;
			font-size: 14px;
			line-height: 17px;
			padding: 5px 10px;
		}
	}
	tbody{
		font-family: 'montserratregular';

		th{
			font-weight: normal;
			&:last-child{
				border-right: none;
			}
		}
		tr{
			th{
				&:nth-child(2){
					color: #000;
				}
			}
			&:last-child{
				th{
					border-bottom: none;
				}
			}
		}

	}

	th{
		padding: 5px 10px;
	}
}
.valor__total__pedido,
.valor__total__detalhe{
	color: #000;
}
.detalhe__total__conteudo{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 18px;
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 18px;
	color: #000;
}
.detalhe__frete__menor{
	padding: 12px 18px 10px 18px;
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 18px;
	color: #000;
	background-color: #e7e7e9;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.col__compra{
	padding: 0px 15px;
}
.finalizar__interna{
	padding: 45px 0px 60px 0px;
}
.pagamento__valor{
	margin-bottom: 5px;
}
@include media-breakpoint-up (lg) {
	.col__compra{
		max-width: 630px;
		width: 100%;
		margin-left: 0px;
		margin-right: auto;
	}
}
@include media-breakpoint-down (md) {
	.col__compra{
		width: 100%;
		margin-bottom: 30px;
		order: 2;
	}
	.col__detalhes{
		margin-right: auto;
		margin-left: auto;
		order: 1;
		margin-bottom: 50px;
		width: 100%;
		max-width: 400px;
	}
	.row__finalizar{
		display: flex;
		flex-direction: column;
	}
}
@include media-breakpoint-down (xs) {
	.segura__btns__pagamento{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.pagamento__card{
		.col-sm-6{
			&:first-child{
				margin-bottom: 20px;
			}
		}
	}
}
