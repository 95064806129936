.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.topo__pesquisa{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.pesquisa__form{
    display: flex;
    overflow: hidden;
    background-color: #787878;
    align-items: center;
	border-radius: 0px;
	border: none;

    .pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #787878;
        color: #fff;
        min-width: 45px;
        width: 45px;
        height: 45px;
        font-size: 18px;
        line-height: 18px;
        border: none;
		border-radius: 0px;
		position: relative;
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'montserratmedium';
        font-size: 13px;
        line-height: 15px;
        padding: 15px 20px 14px 20px;
        border: none;
        background-color: #787878;
        color: #fff;
        border: none;

        &::placeholder{
            color: #fff;
			font-style: italic;
        }

        &:focus{
            outline: none;
        }
    }
    label{
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }
}
.login__icone{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 5px;
}
.carrinho__icone{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 5px;
}
.segura__login__topo{
	font-size: 12px;
	line-height: 16px;
	position: relative;

	a{
		font-family: 'montserratbold';
	}

	.login__icone{
		margin-right: 10px;
	}

    .login__toggler{
        display: flex;
        align-items: center;

        &:hover{
            text-decoration: none;
        }
    }
    .dropdown{
        display: none;
    }

    &.logado{
        .login__toggler{
            display: none;
        }
        .dropdown{
            display: block;
        }
    }
}
.logado__toggler{
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	font-family: 'montserratbold';
	font-size: 12px;
	line-height: 16px;
	padding: 5px 0px;

	&:focus{
		outline: none;
	}
}
.encerrar__item{
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 50px;
        background-color: #2b2c30;
        color: #fff;
        font-family: 'montserratbold';
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        transition: all 0.3s linear;
		padding-top: 2px;

		@include media-breakpoint-up (lg) {
			background: rgb(222,222,222);
			background: linear-gradient(180deg, rgba(222,222,222,1) 0%, rgba(119,119,119,1) 100%);
			border-radius: 50px;
		}

		@include media-breakpoint-down(md) {
            height: 28px;
        }

        &:hover{
            background-color: #787878;
            color: #fff;
            text-decoration: none;
        }
    }
}
.logado__nome{
    padding: 28px 20px 22px 20px;
    background-color: #2b2c30;
    color: #fff;
    font-family: 'montserratregular';
    font-size: 13px;
    line-height: 16px;

	@include media-breakpoint-down (md) {
        padding: 12px 6px 10px 6px;
    }
}
.logado__item{
    padding: 0px 10px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 22px 10px 20px 10px;
        border-bottom: 1px solid rgba(#000, 0.1);
        font-size: 13px;
        line-height: 15px;
        font-family: 'montserratregular';

        &:hover{
            font-family: 'montserratbold';
            color: #787878;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.dropdown-menu{
	border: none;
	padding: 0px;
	margin: 0px;
}
.segura__contato{
	font-style: italic;
	font-size: 12px;
	line-height: 14px;
	font-family: 'montserratmedium';
	margin-bottom: 5px;

	@include media-breakpoint-up (lg) {
		padding-left: 30px;
	}

	.bolder{
		font-family: 'montserratbold';
	}
}
.segura__carrinho__topo{
	font-family: 'montserratregular';
	font-size: 12px;
	line-height: 16px;

	a{
		display: flex;
		align-items: center;

		&:hover{
			text-decoration: none;
		}
	}
}
.btn__toggle__categ{
	background-color: #787878;
	display: flex;
	color: #fff;
	align-items: center;
	justify-content: space-between;
	padding: 20px 5px;
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 16px;
	display: inline-block;
	border: none;
	white-space: nowrap;

	// &:before{
	// 	position: relative;
	// 	display: block;
	// 	width: 20px;
	// 	min-width: 20px;
	// 	height: 20px;
	// 	content: '';
	// 	background-image: url('../images/menu__imagem.png');
	// 	background-repeat: no-repeat;
	// 	margin-right: 10px;
	// }
}
.categoria__destaques,
.categoria__extras{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.categoria__expanded,
.mobile__categorias{
	background-color: #787878;
	color: #fff;

	.categoria__item{
		a{
			display: block;
			padding: 10px;
			border-bottom: 1px solid #fff;
			color: #fff;
			background-color: #787878;
			font-family: 'montserratsemibold';
			transition: all 0.5s linear;
			font-size: 14px;
			line-height: 18px;

			&:hover{
				text-decoration: none;
				background-color: darken(#787878, 10%);
			}
		}
	}
	.dropdown__controls{
		position: relative;

		button{
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(50% - 10px);
			right: 10px;
			background-color: #787878;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			line-height: 12px;
			border-radius: 50%;
			border: 1px solid #fff;
			transition: all 0.3s linear;
			padding: 0px;
			padding-top: 2px;

			span{
				color: #fff;
			}

			&[aria-expanded="true"] {
				transform: rotate(90deg);
			}
		}
	}
	.dropmenu{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;

		// li{
		// 	border-bottom: 1px solid #fff;
		// }

		a{
			display: block;
			width: 100%;
			padding: 10px;
			background-color: #2b2c30;
			color: #fff;
			border-bottom: 1px solid #fff;

			&:hover{
				color: #fff;
				text-decoration: none;
				background-color: lighten(#2b2c30, 10%);
			}
		}
	}
}
.segura__bem__vindo{
	font-size: 12px;
	line-height: 16px;

	span{
		font-family: 'montserratbold';
	}
}
.segura__sociais{
	display: flex;
	align-items: center;

	.sociais__titulo{
		margin-right: 12px;
	}
}
.contato__box__icone{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}
.contato__box{
	display: flex;
	align-items: center;
}
.sociais__nav{
	display: flex;
	justify-content: center;
	align-items: center;

	a{
		width: 30px;
		min-width: 30px;
		height: 30px;
		font-size: 18px;
		line-height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		color: #787878;
		border-radius: 50%;
		margin: 0px 2px;
		transition: all 0.3s linear;

		&:hover{
			background-color: #fff;
			color: #787878;
			text-decoration: none;
			box-shadow: 0px 0px 0px 3px rgba(#fff, 0.3);
		}
	}
}
.carrinho__topo__titulo{
	font-family: 'montserratbold';
}
.carrinho__topo__numero{
	span{
		font-family: 'montserratbold';
	}
}
.contato__box__icone{
	min-width: 35px;
	margin-right: 5px;
}
@include media-breakpoint-up (lg) {
	.segura__contato__alt{
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.contato__box{
			display: flex;
			align-items: center;
			color: #666666;
			font-size: 12px;
			line-height: 14px;
			margin: 0px 10px;
			@include media-breakpoint-up (lg) {
				min-width: 152px;
				max-width: 230px;
			}

			.conteudo__box__tels{
				display: flex;
				align-items: flex-start;
				flex-direction: column;
			}

			.contato__box__emails{
				display: flex;
				flex-direction: column;
			}

			.contato__box__titulo,
			.contato__box__conteudo__titulo{
				font-family: 'montserratextrabold';
			}
			.contato__box__conteudo__titulo{
				display: inline-block;
			}
			.contato__box__conteudo__horarios{
				display: inline-flex;
				flex-direction: column;
			}
		}
	}
	.main__meio{
		padding: 18px 0px;

		.container{
			justify-content: space-between;

		}
	}
	.segura__carrinho__topo{
		padding: 0px 20px;
		border-left: 1px solid #fff;
		border-right: 1px solid #fff;
		margin: 0px 20px;
	}
	.mobile__icone{
		display: none !important;
	}
	.main__meio{
		padding: 10px 0px;
	}
	.main__cima{
		background-color: #787878;
		padding: 6px 0px;
		color: #fff;

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.segura__contatos{
		max-width: 700px;
		width: 100%;
		position: relative;
	}
	.topo__mobile{
		display: none;
	}
	.mobile__categorias{
		display: none;
	}
	.main__pesquisa{
		flex-grow: 1;
		width: 100%;
		max-width: 260px;
		margin-right: 15px;
		position: relative;

		@include media-breakpoint-only (lg) {
			max-width: 150px;
		}

		&:after{
			width: 1px;
			content: '';
			height: 30px;
			background-color: #fff;
			position: absolute;
			left: 0px;
			top: calc(50% - 15px);
		}
	}
	.categoria__expanded{
		position: fixed;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		top: 0px;
		left: -250px;
		transition: all 0.5s linear;
		z-index: 1080;

		&.shown{
			left: 0px;
		}
	}
	.logado__menu{
		min-width: 256px;
        max-width: 100%;
        left: calc(50% - 128px) !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.6);
	}
	.main__categoria{
		position: relative;

		&:after{
			width: 100%;
			z-index: 50;
			position: absolute;

		}

		.desktop__categorias{
			display: flex;
			width: 100%;
			align-items: center;
			font-family: 'montserratmedium';

			.segura__categorias__destaques{
				flex-grow: 1;
				width: 100%;
				display: flex;


				.categoria__destaques{
					display: flex;
					justify-content: space-around;
					width: 100%;
					color: #fff;

					li{
						display: flex;
						position: relative;

						a{
							padding: 20px 5px;
							display: block;
							font-family: 'montserratsemibold';
							color: #fff;

							&:hover{
								text-decoration: none;
								color: #2b2c30;
							}
						}

						.dropmenu{
							position: absolute;
							top: 100% !important;
							width: 200px;
							max-height: calc(100vh - 230px);
							overflow-y: auto;
							background-color: #fff;
							list-style: none;
							padding-left: 0px;
							margin-bottom: 0px;
							box-shadow: 0px 0px 8px rgba(#000, 0.4);

							li{
								display: flex;

								&:last-child{
									a{
										border-bottom: none;
									}
								}

								a{
									display: flex;
									align-items: center;
									justify-content: center;
									padding: 12px 10px;
									width: 100%;
									text-align: center;
									transition: all 0.3s linear;
									font-family: 'montserratsemibold';
									border-bottom: none;
									color: #000;
									font-size: 14px;
									line-height: 14px;

									&:hover{
										text-decoration: none;
										font-family: 'montserratextrabold';
									}
								}
							}
						}

						.dropdown__controls{
							display: flex;

							button{
								display: none;
							}
						}

						&:hover{
							.dropmenu{
								display: block;
							}
						}

						a{
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}

		.container{
			position: relative;
			z-index: 20;
		}
	}
	.logado__toggler{
        position: relative;
        padding: 15px 0px;
		font-family: 'montserratbold';
		font-size: 15px;
		line-height: 17px;
		color: #fff;

        &:after{
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 15px solid #2b2c30;
            content: '';
            position: absolute;
            left: calc(50% - 14px);
            bottom: -3px;
            display: none;
			z-index: 100;
        }

        &[aria-expanded="true"] {
            &:after{
                display: block;
            }
        }
    }
	.topo__main{
		.container{
			display: flex;
			align-items: center;

			.main__right{
				display: flex;
				align-items: center;
				flex-grow: 1;
				width: 100%;
				max-width: 740px;

				.main__conteudo{
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
				}
			}
		}
	}
	.main__logo{
		padding: 0px 0px 0px 0px;
		margin-right: 50px;

		@include media-breakpoint-only (lg) {
			margin-right: 20px;
		}
	}
	.segura__contatos{
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		margin-left: 35px;
		align-items: center;
	}
	.main__categoria{
		background-color: #787878;
	}
	.segura__bolinha{
		display: flex;
		align-items: center;
		justify-content: center;

		.bolinha{
			display: block;
			width: 5px;
			height: 5px;
			background-color: #fff;
			border-radius: 50%;
		}
	}
	.cima__right{
		display: flex;
		align-items: center;
	}
}
@include media-breakpoint-only (lg) {
	.main__categoria{
		.desktop__categorias{
			.btn__toggle__categ{
				&:before{
					display: none;
				}
				padding-right: 15px;
				white-space: normal;
				width: 140px;
			}
		}
	}
	.main__pesquisa{
		max-width: 200px;
	}
	.segura__contatos{
		max-width: 600px;
		.contato__box{
			span{
				display: none;
			}
		}
		.sociais__titulo{
			display: none;
		}
	}
	.main__logo{
		max-width: 240px;
	}
}
@include media-breakpoint-down (md) {
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		z-index: 1080;
		height: 100%;
		overflow-y: auto;
		background-color: #787878;
		transition: all 0.5s linear;
		flex-direction: column;
		display: flex;

		.main__meio{
			order: 2;
			background-color: #fff;
		}
		.main__categoria{
			order: 2;
		}
		.main__cima{
			order: 1;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
		.segura__login__topo{
			display: none;
		}
		.segura__carrinho__topo{
			display: none;
		}
	}
	.contato__box{
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px 10px;
		text-align: center;
		margin-bottom: 10px;

		.contato__box__icone{
			margin-right: 0px;
			margin-bottom: 10px;
		}

		.conteudo__box__tels{
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			.barrinha{
				display: none;
			}
		}
		.contato__box__conteudo__horarios{
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
		.contato__box__emails{
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}

		.contato__box__titulo,
		.contato__box__conteudo__titulo{
			font-family: 'montserratextrabold';
		}

	}
	.topo__mobile{
		padding: 15px 0px;
		border-bottom: 3px solid #787878;

		.container{
			display: grid;
			grid-template-columns: 1fr minmax(auto, 200px) 1fr;
			padding: 0px;
		}
	}
	.mbl__logo{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		font-size: 24px;
		line-height: 24px;
		background-color: transparent;

		&:focus{
			outline: none;
		}
	}
	.segura__bem__vindo{
		background-color: #787878;
		color: #fff;
		text-align: center;
		padding: 10px;

		span{
			font-family: 'montserratbold';
		}
	}
	.main__logo{
		padding: 10px;
		text-align: center;
		margin-bottom: 10px;
	}
	.segura__contato{
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 20px;

		span{
			margin-bottom: 5px;
		}

		.barrinha{
			display: none;
		}
	}
	.main__pesquisa{
		form{
			display: none;
		}
	}
	.login__toggler,
	.logado__toggler{
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		padding: 10px;
		border-bottom: 2px solid #787878;
		width: 100%;

		@include media-breakpoint-up (lg) {
			color: #fff;
		}
	}
	.dropdown{
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.logado__menu{
        min-width: 200px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.6);
        left: -3px !important;

        .logado__link{
            font-family: 'montserratbold';
        }
    }
	.login__icone,
	.carrinho__icone{
		margin: 5px;
	}
	.desktop__categorias{
		display: none;
	}
	.btn__toggle__categ{
		background-color: #000;
		color: #fff;
		padding: 20px;
		width: 100%;
		display: block;
		text-align: center;
		border-bottom: 1px solid #fff;
	}
	.segura__carrinho__topo{
		padding: 15px 0px;
		display: flex;
		justify-content: center;
	}
	.segura__sociais{
		background-color: #787878;
		padding: 10px 0px;
		flex-direction: column;
		color: #fff;
		text-align: center;

		.sociais__titulo{
			margin-right: 0px;
			margin-bottom: 5px;
		}
	}
	.logado__menu{
		border-bottom: 2px solid #e6d9d8;
	}
	.btn__toggle__categ{
		&:before{
			display: none;
		}
	}
	.btn__left__topo{
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.mobile__login{
			margin-left: 10px;

			.segura__login__topo{
				.login__toggler,
				.logado__toggler{
					padding: 15px 0px;
					border: none;

					&[aria-expanded="true"] {
						&:after{
							display: block;
						}
					}

					&:after{
						border-left: 14px solid transparent;
						border-right: 14px solid transparent;
						border-bottom: 15px solid #2b2c30;
						content: '';
						position: absolute;
						left: -3px !important;
						bottom: 0px;
						display: none;
						z-index: 100;
					}

					.login__texto{
						display: none;
					}
					.login__icone{
						display: none;
					}
				}
			}
		}
	}
	.btn__right__topo{
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.mobile__carrinho{
			margin-right: 10px;

			.mobile__icone{
				position: relative;
				display: flex;
				align-items: center;

				.carrinho__numero__mobile{
					font-size: 12px;
					line-height: 12px;
					padding: 3px 5px;
					background-color: #787878;
					color: #fff;
					font-family: 'montserratextrabold';
					order: 2;
					margin: 0px 3px;
				}
				span{
					font-size: 20px;
					line-height: 20px;
					order: 1;
				}
			}

			.carrinho__link__topo{
				.carrinho__icone,
				.carrinho__topo__texto{
					display: none;
				}
			}
		}
	}

}
