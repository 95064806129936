.rodape__cima{
	.container{
		background: rgba(119,119,119,1);
		background: linear-gradient(180deg, rgba(222,222,222,1) 0%, rgba(119,119,119,1) 100%);
		color: #fff;
		padding: 24px 15px;
		margin-bottom: -66px;
		position: relative;
		z-index: 200;
	}

	.row{
		align-items: flex-start;
	}

	.info__card{
		display: flex;
		align-items: flex-start;
		font-size: 13px;
		line-height: 15px;

		.info__card__imagem{
			margin-right: 10px;
			flex-grow: 1;
			min-width: 56px;
			text-align: right;
		}

		.info__card__descricao{
			font-family: 'montserratextrabold';
			font-size: 13px;
			line-height: 15px;
			margin-bottom: 3px;
			padding-top: 13px;
		}

		@include media-breakpoint-down (md) {
			flex-direction: column;
			margin-bottom: 20px;
		}
	}
}
.rodape__conteudo{
	padding-top: 100px;
	background-color: #27282b;
	background-image: url(../images/rodape__bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	color: #fff;
	font-size: 13px;
	line-height: 15px;
}
.duvida__texto,
.duvida__tel{
	font-family: 'montserratextrabold';
}
.duvida__texto{
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 15px;
}
.duvida__tel{
	font-size: 30px;
	line-height: 30px;
	margin-bottom: 15px;
}
.rodape__links,
.rodape__endereco{
	margin-bottom: 15px;
}
.titulo__rodape{
	font-family: 'montserratextrabold';
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 16px;
}
.rodape__nav{
	display: flex;
	flex-direction: column;

	a{
		display: inline-block;
		margin-bottom: 10px;
	}
}
.row__menus__rodape{
	margin-bottom: 70px;
}
.pagamento__rodape{
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'montserratextrabold';
	font-size: 13px;
	line-height: 15px;
	text-align: right;
	padding-top: 20px;
	padding-bottom: 50px;

	.pagamento__texto{
		margin-right: 20px;
	}
}
.creditos{
	background-color: #787878;
	color: #fff;

	@include media-breakpoint-down (md) {
		padding-bottom: 50px;
	}

	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 18px;
		padding-bottom: 18px;

		@include media-breakpoint-down (md) {
			flex-direction: column;
			align-items: center;
		}
	}
}
.creditos__texto{
	font-size: 12px;
	line-height: 14px;

	span{
		font-family: 'montserratbold';
	}
}
.gv8__box{
	display: flex;
	justify-content:flex-start;
	align-items: center;

	svg{
		margin-left: 5px;
	}

	@include media-breakpoint-down (md) {
		justify-content: center;
	}
}
.sociais__rodape{
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border-radius: 50%;
		width: 34px;
		height: 34px;
		font-size: 18px;
		line-height: 18px;
		color: #787878;
		margin: 0px 5px;
		transition: all 0.3s linear;

		&:hover{
			box-shadow: 0px 0px 0px 3px rgba(#fff, 0.4);
			text-decoration: none;
		}
	}
}
@include media-breakpoint-down (md) {
	.rodape__cima{
		.info__card{
			text-align: center;

			.info__card__imagem{
				margin-bottom: 10px;
				margin-right: 0px;
			}
		}
	}
	.rodape__conteudo{
		.col-lg-4{
			margin-bottom: 20px;
		}
	}
	.pagamento__rodape{
		flex-direction: column;
		align-items: center;
		text-align: center;

		.pagamento__texto{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.creditos__texto{
		text-align: center;
	}
	.gv8__box{
		margin-bottom: 10px;
	}
}
.btn__voltar{
	bottom: -100px;
	right: 15px;
	position: fixed;
	z-index: 2000;
	transition: all 0.3s linear;

	&.active{
		bottom: 15px;
	}
}
.btn__voltar{
	bottom: -100px;
	right: 15px;
	position: fixed;
	z-index: 2000;
	transition: all 0.3s linear;
	width: 90px;
	height: 90px;
	left: calc(50% - 45px);
	background-color: #787878;
	color: #fff;
	display: flex;
	justify-content: center;
	padding-top: 12px;
	border-radius: 90px;
	font-size: 24px;
	line-height: 24px;
	box-shadow: 0px 0px 10px rgba(#000, 0.6);

	&.active{
		bottom: -45px;
	}
}
