.categoria__header__cima{
	margin-bottom: 30px;
	.titulo__interna{
		margin-bottom: 30px;
	}
	.controles__categorias{
		display: flex;
		justify-content: flex-end;

		.categoria__botao{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 30px;
			background-color: #2b2c30;
			color: #fff;
			transition: all 0.3s linear;
			border: none;
			margin: 0px 5px;

			&:hover{
				background-color: #2b2c30;
				color: #fff;
				text-decoration: none;
			}
		}
	}
}
.categoria__header{
	background-color: #2b2c30;
	color: #fff;
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;
	text-align: center;
    padding: 16px 20px 14px;
}
.titulo__categoria{
	font-family: 'montserratextrabold';
	font-size: 15px;
	line-height: 18px;
	color: #000;
	margin-bottom: 14px;
}
.categoria__box{
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
    border-left: 1px solid #ededed;
    border-right: 1px solid #ededed;

	&:last-child{
		padding-bottom: 20px;
	}
}
.categoria__lista{
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;
}
.categoria__item__interna{
	display: flex;
	font-family: 'montserratregular';
	font-size: 13px;
	line-height: 15px;
	color: #666666;
	margin-bottom: 4px;

	&:hover{
        text-decoration: underline;
        color: #666666;
        font-weight: 900;
	}
}
.categoria__quant{
	margin-left: 5px;
}
.categoria__mais{
	border: none;
	background-color: transparent;
	padding: 0px;
	font-family: 'montserratsemibold';
	font-size: 13px;
	line-height: 15px;
	color: #000;
	margin-top: 5px;
	display: inline-block;

    span{
        font-size: 12px;
        line-height: 14px;
    }

	&:focus{
		outline: none;
	}
}
.categoria__segura__radio{
	color: #666666;
	.categoria__radio{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .categoria__label{

				&:before{
					border-color: #666666;
					background-color: #fff;
				}
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.categoria__label{
		padding-left: 20px;
		position: relative;
		font-family: 'montserratregular';
		font-size: 13px;
		line-height: 15px;
		color: #666666;
		transition: all 0.3s linear;
		cursor: pointer;

		&:hover{

			&:before{
				border-color: #666666;
				background-color: #fff;
			}
		}

		&:before{
			content: '';
			position: absolute;
			top: 30%;
			transform: translateY(-50%);
			left: 0px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 1px solid #666666;
            transition: all 0.3s linear;
            margin-top: 2px;
		}

		&:after{
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #666666;
			top: 30%;
			left: 3px;
			transform: translateY(-1px);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
}
.categoria__interna{
    .col-xl-9{
        margin-bottom: 10px;

        .titulo__interna{
            margin-bottom: 10px;
        }

        .col-lg-6{
            margin-bottom: 20px;
        }
    }
    .paginacao{
        padding-top: 30px;
    }
}
.categoria__mais{
	&[aria-expanded="true"] {
		&.categ__mais{
			display: none;
		}
	}
	&[aria-expanded="false"] {
		&.categ__menos{
			display: none;
		}
	}
}
.paginacao{
	display: flex;
	justify-content: flex-end;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;

	.paginacao__item{
		a{
            font-family: 'montserratsemibold';
            font-size: 17px;
            line-height: 17px;
            color: #000;
            display: flex;
            width: 34px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 34px;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s linear;
			border: none;
            margin: 0px 2px;
            padding: 0px;
            padding-left: 1px;
            padding-top: 1px;

            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
				background: rgba(119,119,119,1);
				background: linear-gradient(180deg, rgba(222,222,222,1) 0%, rgba(119,119,119,1) 100%);
                color: #fff;
                text-decoration: underline;
           }
        }

		&:hover{
			a{
                text-decoration: underline;
            }
		}
	}
}
.card__interna__imagem{
    position: relative;
    margin-bottom: 8px;

    .promocao__porcentagem{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #130083;
        color: #fff;
        font-size: 16px;
        line-height: 16px;
        top: 10px;
        left: 10px;
    }
    .btn__comprar{
        position: absolute;
        display: inline-block;
        padding: 10px 20px;
        border-radius: 15px;
        background-color: #2F2F2F;
        color: #fff;
        font-family: 'montserratbold';
        font-size: 16px;
        line-height: 18px;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: all 0.3s linear;

        &:hover{
            background-color: #130083;
            color: #2F2F2F;
            text-decoration: none;
        }
    }
}
.produto__card__interna{
    &:hover{
        .btn__comprar{
            opacity: 1;
        }
    }
}
.card__interna__nome{
    font-family: 'montserratregular';
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 10px;
}
.card__interna__valor{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #130083;
    flex-wrap: wrap;
}
.card__interna__parcelar{
    font-size: 15px;
    line-height: 19px;
    color: #878787;
    font-family: 'montserratregular';
}
.card__interna__total{
    font-size: 26px;
    line-height: 30px;
    font-family: 'montserratsemibold';
}
.card__interna__promocao{
    text-decoration: line-through;
    margin-bottom: 2px;
    margin-right: 5px;
}
.card__interna__parcelar{
    text-align: center;
}
.card__interna__link{
    display: block;

    &:hover{
        text-decoration: none;

        .card__interna__nome{
            text-decoration: underline;
        }
    }
}
.categorias__listagem{
    border-bottom: 1px solid #ededed;
}
.depoimento__card{
    margin-bottom: 40px;
    color: #000;

    .depoimento__desc{
        text-align: justify;
        margin-bottom: 4px;
    }
    .depoimento__titulo{
        font-family: 'montserratbold';
    }
}
.subcategoria__lista{
	padding-left: 15px;
	list-style: none;
	margin-bottom: 5px;

	.subcategoria__item{
		margin-bottom: 5px;

		.categoria__item__interna{
			font-size: 13px;
			line-height: 15px;
			font-family: 'montserratregular';
		}
	}
}
.segura__categoria__controls{
	display: flex;
	align-items: center;

	button{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		background-color: #2b2c30;
		color: #fff;
		border-radius: 50%;
		border: none;
		min-width: 15px;
		min-height: 15px;
		width: 15px;
		height: 15px;
		padding: 0px;
		font-size: 8px;
		line-height: 8px;
		margin-left: 5px;
		margin-bottom: 2px;
		transition: all 0.3s linear;
		padding-top: 0px;
		padding-right: 0px;

		&:focus{
			outline: none;
		}
		&[aria-expanded="true"] {
			transform: rotate(90deg);
		}
	}
}

@include media-breakpoint-down (md) {
	.categorias__listagem{
		margin-bottom: 30px;
	}
}
@include media-breakpoint-down (lg) {
    .categoria__interna{
        .produto__card{
            .produto__valores{
                flex-direction: column;
                align-items: center;

                .produto__valor__promocao{
                    padding-right: 0px;

                    &:after{
                        display: none;
                    }
                }
                .produto__valor{
                    padding-left: 0px;
                }
            }
        }
    }
}
